body {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 8px;
  font-family: Arial, Helvetica, sans-serif;
}
.upload-box {
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  margin: 10px auto;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: white;
  input {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 200px;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
}
.upload-form {
  background: #f8f8f8;
  padding: 20px 0;
}
.upload-progress {
  .file-list {
    padding: 0;
    list-style: none;
    >li {
      padding: 5px 10px;
      background: #f8f8f8;
      display: flex;
      &:nth-of-type(2n) {
        background: #eee;
      }
      span {
        display: block;
        &.progress {
          margin-left: auto;
          margin-right: 16px;
          padding: 0 4px;
        }
      }
      &.done {
        .progress {
          background: #aaffaa;
        }
      }
    }
  }
}
